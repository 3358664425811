import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Infobar.css';

const Infobar: React.FC = () => {
    return (
        <footer id='infobar'>
            <div className='footer-section'>
                <h3 className='lang-fr'>Contactez nous</h3>
                <h3 className='lang-en'>Contact us</h3>
                <div className='footer-line'>
                    <i className='fas fa-map-marker-alt' />
                    <p className='lang-fr'> Adresse : </p>
                    <p className='lang-en'> Address : </p>
                    <a href='https://maps.app.goo.gl/Y4Ds6c3uUZM1t8gLA' target='_blank' rel='noreferrer'>
                        <p className='lang-fr'>Salle B007, Bâtiment 620, Maison de l'Ingénieur, Rue Louis de Broglie, 91190 Orsay, France</p>
                        <p className='lang-en'>Room B007, Building 620, Maison de l'Ingénieur, Road Louis de Broglie, 91190 Orsay, France</p>
                    </a>
                </div>
                <div className='footer-line'>
                    <i className='fas fa-envelope' /> Email :
                    <a href='mailto:cia.polytech@gmail.com' target='_blank' rel='noreferrer'> cia.polytech@gmail.com</a>
                </div>
                <div className='footer-line'>
                    <i className='fas fa-phone' />
                    <p className='lang-fr'> Téléphone : </p>
                    <p className='lang-en'> Phone : </p>
                    <a href='tel:+33000000000' target='_blank' rel='noreferrer'>(+33) 00.00.00.00.00</a>
                </div>
            </div>
            <div className='footer-section'>
                <h3 className='lang-fr'>Liens utiles</h3>
                <h3 className='lang-en'>Quick Links</h3>
                <Link to='/' className='footer-line'>
                    <i className='fas fa-home' />
                    <p className='lang-fr'> Accueil</p>
                    <p className='lang-en'> Home</p>
                </Link>
                <Link to='/rules' className='footer-line'>
                    <i className='fas fa-scale-balanced' />
                    <p className='lang-fr'> Règles</p>
                    <p className='lang-en'> Rules</p>
                </Link>
                <Link to='/scoreboard' className='footer-line'>
                    <i className='fas fa-clock' />
                    <p className='lang-fr'> Résultat</p>
                    <p className='lang-en'> Scoreboard</p>
                </Link>
                <Link to='/leaderboard' className='footer-line'>
                    <i className='fas fa-trophy' />
                    <p className='lang-fr'> Podium</p>
                    <p className='lang-en'> Leaderboard</p>
                </Link>
            </div>
            <div className='footer-section'>
                <h3 className='lang-fr'>Suivez nous</h3>
                <h3 className='lang-en'>Follow us</h3>
                <a href='https://discord.gg/S8gRM95wqw' target='_blank' rel='noreferrer' className='footer-line'><i className='fab fa-discord' /> Discord</a>
                <a href='https://github.com/CIA-PoPS' target='_blank' rel='noreferrer' className='footer-line'><i className='fab fa-github' /> GitHub</a>
                <a href='https://www.facebook.com/profile.php?id=61555761136479' target='_blank' rel='noreferrer' className='footer-line'><i className='fab fa-facebook' /> Facebook</a>
                <a href='https://www.instagram.com/cia_polytech_paris_saclay/' target='_blank' rel='noreferrer' className='footer-line'><i className='fab fa-instagram' /> Instagram</a>
            </div>
        </footer>
    );
};

export default Infobar;
