type RGB = { r: number, g: number, b: number };

const interpolateColor = (color1: RGB, color2: RGB, factor: number): RGB => {
    return {
        r: Math.round(color1.r + (color2.r - color1.r) * factor),
        g: Math.round(color1.g + (color2.g - color1.g) * factor),
        b: Math.round(color1.b + (color2.b - color1.b) * factor),
    };
};

export const percentageToColor = (percentage: number): string => {
    const red = { r: 255, g: 0, b: 0 };
    const orange = { r: 255, g: 165, b: 0 };
    const yellow = { r: 255, g: 255, b: 0 };
    const green = { r: 0, g: 128, b: 0 };
    let color: RGB;
    if (percentage <= 33)
        color = interpolateColor(red, orange, percentage / 33);
    else if (percentage <= 66)
        color = interpolateColor(orange, yellow, (percentage - 33) / 33);
    else
        color = interpolateColor(yellow, green, (percentage - 66) / 34);
    return `rgb(${color.r}, ${color.g}, ${color.b})`;
};

export class Time {
    time: number;

    constructor(time: number) {
        this.time = time;
    }

    get(): number {
        return this.time;
    }

    add(value: number) {
        this.time += value;
    }

    toString(): string {
        const minutes = Math.floor(this.time / 6000);
        const seconds = Math.floor(this.time / 100) % 60;
        const milliseconds = this.time % 100;
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}.${milliseconds.toString().padStart(2, '0')}`;
    }
}

export class GroupScore {
    score: number;
    total_time: Time;
    solved: number;
    times: Time[];

    constructor(
        public room: number,
        public slot: number,
        public team: string,
        public group: number,
        public members: number,
        total_time: number,
        times: number[],
        public errors: number
    ) {
        this.total_time = new Time(total_time);
        this.times = times.map(time => new Time(((0 < time) && (time < total_time)) ? time : total_time));

        this.solved = this.times.filter(instance => instance.get() < total_time).length;

        this.score = this.times.reduce((accumulator, currentValue) => {
            return accumulator + total_time - currentValue.get();
        }, 0);;
    }
}

export const parseGroupScores = (text: string): GroupScore[] => {
    const lines = text.split('\n').filter(line => line.trim() !== '');

    return lines.map(line => {
        const parts = line.split(' - ');

        if (parts.length < 7)
            throw new Error(`Unexpected format: ${line}`);

        const room = parseInt(parts[0].replace('Room ', '').trim(), 10);
        const slot = parseInt(parts[1].replace('Slot ', '').trim(), 10);
        const team = parts[2].replace('Team ', '').trim().toLowerCase();
        const group = parseInt(parts[3].replace('Group ', '').trim(), 10);
        const members = parseInt(parts[4].replace('Members ', '').trim(), 10);
        const total_time = parseInt(parts[5].replace('Total time ', '').trim(), 10);
        const times = parts[6].replace('Times ', '').split(', ').map(Number);
        const errors = parseInt(parts[7].replace('Errors ', '').trim(), 10);

        return new GroupScore(room, slot, team, group, members, total_time, times, errors);
    });
};

export class TeamScore {
    total_time: Time;
    times: Time[];

    constructor(
        public team: string,
        public members: number,
        public score: number,
        public bonus: number,
        public solved: number,
        total_time: number,
        times: number[],
        public errors: number
    ) {
        this.total_time = new Time(total_time);
        this.times = times.map(time => new Time(time));
    }
}

export const parseTeamScores = (groups: GroupScore[], bonus: string): TeamScore[] => {
    const teamScores: { [key: string]: TeamScore } = {};
    const teamBonus: { [key: string]: number} = {};

    const lines = bonus.split('\n').filter(line => line.trim() !== '');

    lines.forEach(line => {
        const parts = line.split(' ');

        if (parts.length !== 2)
            throw new Error(`Unexpected format: ${line}`);

        teamBonus[parts[0].trim().toLowerCase()] = parseInt(parts[1].trim(), 10)
    })

    groups.forEach(group => {
        if (!teamScores[group.team]) {
            let bonus: number;

            if (!teamBonus[group.team])
                bonus = 0;
            else
                bonus = teamBonus[group.team];

            teamScores[group.team] = new TeamScore(
                group.team,
                group.members,
                group.score,
                bonus,
                group.solved,
                group.total_time.get(),
                group.times.map(time => {
                    if (time.get() < group.total_time.get())
                        return time.get()
                    else
                        return 0
                }),
                group.errors
            );
        } else {
            const existingTeamScore = teamScores[group.team];

            existingTeamScore.members += group.members;
            existingTeamScore.score += group.score;
            existingTeamScore.solved += group.solved;
            existingTeamScore.total_time.add(group.total_time.get());
            existingTeamScore.errors += group.errors;

            if (group.times.length <= existingTeamScore.times.length)
                group.times.forEach((time, index) => {
                    if (time.get() < group.total_time.get())
                        existingTeamScore.times[index].add(time.get())
                });
            else {
                const tmp_times = group.times.map(time => {
                    if (time.get() < group.total_time.get())
                        return new Time(time.get())
                    else
                        return new Time(0)
                });
                existingTeamScore.times.forEach((time, index) => {
                    if (group.times[index].get() < group.total_time.get())
                        tmp_times[index].add(time.get())
                });
                existingTeamScore.times = tmp_times;
            }
        }
    });

    return Object.values(teamScores);
};
