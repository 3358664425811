// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rules-main {
    background-color: rgba(127, 255, 212, 0.2);
    border-radius: 50px;
}

.rules-main h2 {
    font-size: 48px;
    margin: 50px 0;
}

.rules-main li {
    display: list-item;
    list-style: decimal inside;
    margin: 15px 0;
    font-size: 24px;
}

.rules-main li p {
    display: inline;
    font-size: 24px;
}
`, "",{"version":3,"sources":["webpack://./src/styles/Rules.css"],"names":[],"mappings":"AAAA;IACI,0CAA0C;IAC1C,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,0BAA0B;IAC1B,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,eAAe;AACnB","sourcesContent":[".rules-main {\n    background-color: rgba(127, 255, 212, 0.2);\n    border-radius: 50px;\n}\n\n.rules-main h2 {\n    font-size: 48px;\n    margin: 50px 0;\n}\n\n.rules-main li {\n    display: list-item;\n    list-style: decimal inside;\n    margin: 15px 0;\n    font-size: 24px;\n}\n\n.rules-main li p {\n    display: inline;\n    font-size: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
