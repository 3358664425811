import React from 'react';
import '../styles/Home.css';

const Home: React.FC = () => {
    return (
        <main id='main'>
            <section className='welcome-section'>
                <h1 className='lang-fr'>Bienvenue sur le site du CIA !</h1>
                <h1 className='lang-en'>Welcome on CIA's website !</h1>
            </section>
            <section className='info-section'>
                <div className='info-block'>
                    <img src='/images/logo_cia.svg' alt='Qui sommes nous ?' />
                    <div className='info-text'>
                        <h2 className='lang-fr'>Qui sommes nous ?</h2>
                        <h2 className='lang-en'>Who are we ?</h2>
                        <p className='lang-fr'>Le Club d'Informatique et d'Algorithmique (CIA) est un groupe d'étudiants passionnés par l'informatique et les algorithmes à l'école d'ingénieur. Nous nous réunissons régulièrement pour travailler sur des projets, organiser des compétitions et partager nos connaissances avec les autres membres. Notre mission est de promouvoir l'intérêt pour l'informatique et de fournir un environnement où les étudiants peuvent apprendre, collaborer et innover.</p>
                        <p className='lang-en'>The Computer Science and Algorithmics Club (CIA) is a group of students passionate about computer science and algorithms at the engineering school. We meet regularly to work on projects, organize competitions and share our knowledge with other members. Our mission is to promote interest in computer science and provide an environment where students can learn, collaborate and innovate.</p>
                    </div>
                </div>
                <div className='info-block reverse'>
                    <img src='/images/home/event.png' alt='Evenement' />
                    <div className='info-text'>
                        <h2 className='lang-fr'>Évènement</h2>
                        <h2 className='lang-en'>Event</h2>
                        <p className='lang-fr'>Nous organisons régulièrement des événements liés à l'informatique, tels que des hackathons, des ateliers de programmation et des compétitions d'algorithmique. Ces événements offrent aux étudiants l'occasion de mettre en pratique leurs compétences, de se mesurer à d'autres passionnés et de se préparer pour des compétitions de plus grande envergure.</p>
                        <p className='lang-en'>We regularly organize IT-related events, such as hackathons, programming workshops and algorithmic competitions. These events provide students with the opportunity to practice their skills, compete against other enthusiasts, and prepare for larger competitions.</p>
                    </div>
                </div>
                <div className='info-block'>
                    <img src='/images/home/courses.png' alt='Cours' />
                    <div className='info-text'>
                        <h2 className='lang-fr'>Cours</h2>
                        <h2 className='lang-en'>Courses</h2>
                        <p className='lang-fr'>Nous proposons des cours et des ateliers pour aider les membres à améliorer leurs compétences en programmation et en algorithmique. Que vous soyez débutant ou expérimenté, vous trouverez des ressources et des formations adaptées à votre niveau. Nos cours couvrent un large éventail de sujets, allant des bases de la programmation aux techniques avancées d'algorithmes.</p>
                        <p className='lang-en'>We offer courses and workshops to help members improve their programming and algorithmic skills. Whether you are a beginner or experienced, you will find resources and training adapted to your level. Our courses cover a wide range of topics, from programming basics to advanced algorithm techniques.</p>
                    </div>
                </div>
                <div className='info-block reverse'>
                    <img src='/images/home/office.png' alt='Bureau' />
                    <div className='info-text'>
                        <h2 className='lang-fr'>Bureau</h2>
                        <h2 className='lang-en'>Office</h2>
                        <p className='lang-fr'>Voici notre bureau actuel: Maxime DAUPHIN (Prez), Julien TAP (VP), Maël HOUPLINE (Trez), David Luca (Repso. Projet), Simon RENARD (Respo. Algo) et Rémi RUELLE (Respo. Comm). Nous sommes là pour vous aider et répondre à vos questions.</p>
                        <p className='lang-en'>Here is our current office: Maxime DAUPHIN (Prez), Julien TAP (VP), Maël HOUPLINE (Trez), David Luca (Repso. Project), Simon RENARD (Respo. Algo) and Rémi RUELLE (Respo. Comm). We are here to help you and answer your questions.</p>
                    </div>
                </div>
            </section>
        </main>  
    );
};

export default Home;
