import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { GroupScore, parseGroupScores, TeamScore, parseTeamScores } from './script/Score';
import Navbar from './components/Navbar';
import Infobar from './components/Infobar';
import HUD from './components/HUD';
import Rick from './pages/Rick';
import Home from './pages/Home';
import Scoreboard from './pages/Scoreboard';
import Leaderboard from './pages/Leaderboard';
import Rules from './pages/Rules';
import './App.css';
import './styles/General.css';

const App: React.FC = () => {
    const [groupScores, setGroupScores] = useState<GroupScore[]>([]);
    const [teamScores, setTeamScores] = useState<TeamScore[]>([]);

    useEffect(() => {
        fetch('/data/result.txt')
            .then(response => response.text())
            .then(text => {
                const gscores = parseGroupScores(text);
                setGroupScores(gscores);
                fetch('/data/bonus.txt')
                    .then(response => response.text())
                    .then(text2 => {
                        const tscores = parseTeamScores(gscores, text2);
                        setTeamScores(tscores);
                    })
                    .catch(error => {
                        console.error('Error fetching or parsing the file for bonus:', error);
                    });
            })
            .catch(error => {
                console.error('Error fetching or parsing the file for result:', error);
            });
    }, []);

    useEffect(() => {
        const setDeviceMult = () => {
            const deviceMult = Math.max(Math.min(window.innerHeight, window.innerWidth) / 838, 0.25);
            document.documentElement.style.setProperty('--devive-multiplier', `${deviceMult}`);
        };
        setDeviceMult();

        const updateHeights = () => {
            const navbarHeight = document.getElementById('navbar')?.offsetHeight || 0;
            const infobarHeight = document.getElementById('infobar')?.offsetHeight || 0;
            document.documentElement.style.setProperty('--navbar-height', `${navbarHeight}px`);
            document.documentElement.style.setProperty('--infobar-height', `${infobarHeight}px`);
        };
        updateHeights();
        window.addEventListener('resize', updateHeights);
        return () => {
            window.removeEventListener('resize', updateHeights);
        }
    }, []);

    return (
        <Router>
            <input type='checkbox' id='mode-lang' />
            <input type='checkbox' id='mode-theme' />
            <div className='fixed-background'></div>
            <Navbar/>
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/inte' element={<Rick />} />
                <Route path='/rules' element={<Rules />} />
                <Route path='/scoreboard' element={<Scoreboard scores={groupScores} />} />
                <Route path='/leaderboard' element={<Leaderboard scores={teamScores} />} />
            </Routes>
            <Infobar />
            <HUD />
        </Router>
    );
};

export default App;
