// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.welcome-section {
    height: 50vh;
    display: flex;
    align-items: center;
}

.welcome-section h1 {
    font-size: 72px;
    animation: slideBottom 1.5s ease-in-out, fadeIn 2.0s ease-in-out;
}

.info-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
}

.info-block {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    text-align: left;
    animation: slideBottom linear, fadeIn linear;
    animation-timeline: view();
    animation-range: cover 0 cover 20%;
}

.info-block.reverse {
    flex-direction: row-reverse;
    text-align: right;
}

.info-block img {
    width: 200px;
    height: 200px;
    max-width: 25vw;
    max-height: 25vw;
    border-radius: 50px;
}

.info-text {
    max-width: 60%;
}

.info-text h2 {
    font-size: 24px;
    margin-bottom: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/styles/Home.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,gEAAgE;AACpE;;AAEA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,2BAA2B;IAC3B,SAAS;IACT,gBAAgB;IAChB,4CAA4C;IAC5C,0BAA0B;IAC1B,kCAAkC;AACtC;;AAEA;IACI,2BAA2B;IAC3B,iBAAiB;AACrB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,eAAe;IACf,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,mBAAmB;AACvB","sourcesContent":[".welcome-section {\n    height: 50vh;\n    display: flex;\n    align-items: center;\n}\n\n.welcome-section h1 {\n    font-size: 72px;\n    animation: slideBottom 1.5s ease-in-out, fadeIn 2.0s ease-in-out;\n}\n\n.info-section {\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 50px;\n}\n\n.info-block {\n    display: flex;\n    align-items: center;\n    justify-content: flex-start;\n    gap: 20px;\n    text-align: left;\n    animation: slideBottom linear, fadeIn linear;\n    animation-timeline: view();\n    animation-range: cover 0 cover 20%;\n}\n\n.info-block.reverse {\n    flex-direction: row-reverse;\n    text-align: right;\n}\n\n.info-block img {\n    width: 200px;\n    height: 200px;\n    max-width: 25vw;\n    max-height: 25vw;\n    border-radius: 50px;\n}\n\n.info-text {\n    max-width: 60%;\n}\n\n.info-text h2 {\n    font-size: 24px;\n    margin-bottom: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
