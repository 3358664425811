// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../public/images/logo_cia_marge_back.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes slideBottom {
    0% { transform: translateY(250px); }
    100% { transform: translateY(0px); }
}

@keyframes revealBottom {
    0% { height: 0; }
    100% { height: 100%; }
}

#root {
    width: 100%;
    height: 100%;
    min-width: 400px;
}

.fixed-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: 50% 70px;
    background-size: auto calc(100% - 70px);
    z-index: -1;
}

#main {
    width: 95%;
    min-height: calc(100% - 2*20px - var(--navbar-height) - var(--infobar-height));
    margin: 20px 2.5%;
    margin-top: 20px;
    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

hr {
    width: 95%;
    height: 3px;
    margin: 20px auto;
    background-color: black;
    border-radius: 5px;
}

#mode-lang:checked ~ * .lang-fr, #mode-lang:not(:checked) ~ * .lang-en {
    display: none;
}
`, "",{"version":3,"sources":["webpack://./src/styles/General.css"],"names":[],"mappings":"AAAA;IACI,KAAK,UAAU,EAAE;IACjB,OAAO,UAAU,EAAE;AACvB;;AAEA;IACI,KAAK,4BAA4B,EAAE;IACnC,OAAO,0BAA0B,EAAE;AACvC;;AAEA;IACI,KAAK,SAAS,EAAE;IAChB,OAAO,YAAY,EAAE;AACzB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,yDAAoE;IACpE,4BAA4B;IAC5B,4BAA4B;IAC5B,6BAA6B;IAC7B,uCAAuC;IACvC,WAAW;AACf;;AAEA;IACI,UAAU;IACV,8EAA8E;IAC9E,iBAAiB;IACjB,gBAAgB;IAChB,aAAa;IACb,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI,UAAU;IACV,WAAW;IACX,iBAAiB;IACjB,uBAAuB;IACvB,kBAAkB;AACtB;;AAEA;IACI,aAAa;AACjB","sourcesContent":["@keyframes fadeIn {\n    0% { opacity: 0; }\n    100% { opacity: 1; }\n}\n\n@keyframes slideBottom {\n    0% { transform: translateY(250px); }\n    100% { transform: translateY(0px); }\n}\n\n@keyframes revealBottom {\n    0% { height: 0; }\n    100% { height: 100%; }\n}\n\n#root {\n    width: 100%;\n    height: 100%;\n    min-width: 400px;\n}\n\n.fixed-background {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-image: url(\"../../public/images/logo_cia_marge_back.svg\");\n    background-repeat: no-repeat;\n    background-attachment: fixed;\n    background-position: 50% 70px;\n    background-size: auto calc(100% - 70px);\n    z-index: -1;\n}\n\n#main {\n    width: 95%;\n    min-height: calc(100% - 2*20px - var(--navbar-height) - var(--infobar-height));\n    margin: 20px 2.5%;\n    margin-top: 20px;\n    padding: 20px;\n    text-align: center;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    gap: 15px;\n}\n\nhr {\n    width: 95%;\n    height: 3px;\n    margin: 20px auto;\n    background-color: black;\n    border-radius: 5px;\n}\n\n#mode-lang:checked ~ * .lang-fr, #mode-lang:not(:checked) ~ * .lang-en {\n    display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
