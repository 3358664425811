// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.leaderboard-main h3 {
    font-size: 32px;
    margin: 25px 0;
}

.leaderboard-main button {
    width: 45%;
    background: gray;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.leaderboard-main button:hover {
    background-color: darkgray;
}

.leaderboard-main button p {
    font-size: 24px;
    display: inline;
}

.leaderboard-score {
    width: 95%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
    gap: 30px;
    overflow-x: auto;
}

.team-score {
    min-width: 200px;
    max-width: 200px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}

.team-score h4 {
    font-size: 32px;
}

.team-score p {
    font-size: 16px;
}

.team-bar-container {
    width: 100%;
    height: 50vh;
    display: flex;
    align-items: flex-end;
}

.team-bar {
    width: 100%;
    animation: revealBottom 5s ease-in-out forwards;
}

.team-detail {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.team-detail p {
    color: inherit;
    display: inline;
}
`, "",{"version":3,"sources":["webpack://./src/styles/Leaderboard.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,UAAU;IACV,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,UAAU;IACV,aAAa;IACb,mBAAmB;IACnB,6BAA6B;IAC7B,uBAAuB;IACvB,SAAS;IACT,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,gBAAgB;IAChB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,qBAAqB;AACzB;;AAEA;IACI,WAAW;IACX,+CAA+C;AACnD;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,eAAe;AACnB","sourcesContent":[".leaderboard-main h3 {\n    font-size: 32px;\n    margin: 25px 0;\n}\n\n.leaderboard-main button {\n    width: 45%;\n    background: gray;\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    align-items: center;\n    gap: 15px;\n}\n\n.leaderboard-main button:hover {\n    background-color: darkgray;\n}\n\n.leaderboard-main button p {\n    font-size: 24px;\n    display: inline;\n}\n\n.leaderboard-score {\n    width: 95%;\n    display: flex;\n    flex-direction: row;\n    justify-content: space-evenly;\n    align-items: flex-start;\n    gap: 30px;\n    overflow-x: auto;\n}\n\n.team-score {\n    min-width: 200px;\n    max-width: 200px;\n    overflow: hidden;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 15px;\n}\n\n.team-score h4 {\n    font-size: 32px;\n}\n\n.team-score p {\n    font-size: 16px;\n}\n\n.team-bar-container {\n    width: 100%;\n    height: 50vh;\n    display: flex;\n    align-items: flex-end;\n}\n\n.team-bar {\n    width: 100%;\n    animation: revealBottom 5s ease-in-out forwards;\n}\n\n.team-detail {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.team-detail p {\n    color: inherit;\n    display: inline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
