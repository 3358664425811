import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Navbar.css';

const Navbar: React.FC = () => {
    const year = new Date().getFullYear();
    return (
        <header id='navbar'>
            <Link to='/' className='logo-link'>
                <img src='/images/logo_cia_marge.svg' alt='Logo' className='logo' />
                <div className='title-container navbar-first'>
                    <h1>CIA - POPS</h1>
                    <p>{year}</p>
                </div>
            </Link>
            <nav>
                <ul>
                    <li>
                        <Link to='/rules'>
                            <i className='fas fa-scale-balanced' />
                            <p className='lang-fr navbar-second'>Règles</p>
                            <p className='lang-en navbar-second'>Rules</p>
                        </Link>
                    </li>
                    <li>
                        <Link to='/scoreboard'>
                            <i className='fas fa-clock' />
                            <p className='lang-fr navbar-second'>Résultat</p>
                            <p className='lang-en navbar-second'>Scoreboard</p>
                        </Link>
                    </li>
                    <li>
                        <Link to='/leaderboard'>
                            <i className='fas fa-trophy' />
                            <p className='lang-fr navbar-second'>Podium</p>
                            <p className='lang-en navbar-second'>Leaderboard</p>
                        </Link>
                    </li>
                </ul>
            </nav>
        </header>
    );
};

export default Navbar;
