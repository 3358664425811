import React from 'react';
import '../styles/Rules.css';

const Rules: React.FC = () => {
    return (
        <main id='main' className='rules-main'>
            <h2 className='lang-fr'>Règles de l'escape game</h2>
            <h2 className='lang-en'>Escape game's rules</h2>
            <hr />
            <ul>
                <li>
                    <p className='lang-fr'>Pas d'appareil numérique</p>
                    <p className='lang-en'>No digital device</p>
                </li>
                <li>
                    <p className='lang-fr'>Pas de divulgation d'information, sinon pénalité</p>
                    <p className='lang-en'>No disclosure of information, otherwise penalty</p>
                </li>
                <li>
                    <p className='lang-fr'>Ne pas saccager la salle</p>
                    <p className='lang-en'>Don't trash the room</p>
                </li>
                <li>
                    <p className='lang-fr'>Ne pas détruire le matériel/les énigmes</p>
                    <p className='lang-en'>Do not destroy the materials/enigmas</p>
                </li>
                <li>
                    <p className='lang-fr'>Aucun conseil du staff, en dehors des indices qui vous seront donnés en fonction du temps restant</p>
                    <p className='lang-en'>No advice from the staff, apart from the clues which will be given to you depending on the time remaining</p>
                </li>
                <li>
                    <p className='lang-fr'>Zone du staff interdite d'accès</p>
                    <p className='lang-en'>Staff area prohibited from access</p>
                </li>
                <li>
                    <p className='lang-fr'>6 énigmes éparpillées dans la salle</p>
                    <p className='lang-en'>6 enigmas scattered around the room</p>
                </li>
                <li>
                    <p className='lang-fr'>Bien se répartir les tâches</p>
                    <p className='lang-en'>Distribute tasks well</p>
                </li>
            </ul>
        </main>
    );
};

export default Rules;
