import React from 'react';
import '../styles/HUD.css';

const HUD: React.FC = () => {
    const changeLanguage = () => {
        const modeLang = document.getElementById('mode-lang') as HTMLInputElement;
        modeLang.checked = !modeLang.checked;
        const navbarHeight = document.getElementById('navbar')?.offsetHeight || 0;
        const infobarHeight = document.getElementById('infobar')?.offsetHeight || 0;
        document.documentElement.style.setProperty('--navbar-height', `${navbarHeight}px`);
        document.documentElement.style.setProperty('--infobar-height', `${infobarHeight}px`);
    };

    const goToTop = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
        document.documentElement.scrollTo({ top: 0, behavior: 'smooth' });
        document.body.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <div id='hud' className='hud'>
            <button className='hud-button' onClick={changeLanguage}>
                <p className='lang-fr'>FR</p>
                <p className='lang-en'>EN</p>
            </button>
            <button className='hud-button' onClick={goToTop}>
                <i className='fas fa-arrow-up' />
            </button>
        </div>
    );
};

export default HUD;
