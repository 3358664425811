// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
    position: relative;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    display: block;
    width: auto;
    height: auto;
    padding: 0;
    border: none;
    margin: 0;
    background: none;
    transform: none;
    text-decoration: none;
    box-sizing: border-box;
    z-index: inherit;
    text-align: inherit;
    color: inherit;
    opacity: inherit;
    font-family: inherit;
    font-size: inherit;
}

body, html {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    z-index: 0;
    text-align: left;
    color: black;
    opacity: 1;
    font-family: Arial, sans-serif;
    font-size: 16px;
    overflow: auto;
}

head, input {
    display: none;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,SAAS;IACT,YAAY;IACZ,UAAU;IACV,WAAW;IACX,cAAc;IACd,WAAW;IACX,YAAY;IACZ,UAAU;IACV,YAAY;IACZ,SAAS;IACT,gBAAgB;IAChB,eAAe;IACf,qBAAqB;IACrB,sBAAsB;IACtB,gBAAgB;IAChB,mBAAmB;IACnB,cAAc;IACd,gBAAgB;IAChB,oBAAoB;IACpB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,UAAU;IACV,gBAAgB;IAChB,YAAY;IACZ,UAAU;IACV,8BAA8B;IAC9B,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,aAAa;AACjB","sourcesContent":["* {\n    position: relative;\n    top: auto;\n    bottom: auto;\n    left: auto;\n    right: auto;\n    display: block;\n    width: auto;\n    height: auto;\n    padding: 0;\n    border: none;\n    margin: 0;\n    background: none;\n    transform: none;\n    text-decoration: none;\n    box-sizing: border-box;\n    z-index: inherit;\n    text-align: inherit;\n    color: inherit;\n    opacity: inherit;\n    font-family: inherit;\n    font-size: inherit;\n}\n\nbody, html {\n    width: 100%;\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n    z-index: 0;\n    text-align: left;\n    color: black;\n    opacity: 1;\n    font-family: Arial, sans-serif;\n    font-size: 16px;\n    overflow: auto;\n}\n\nhead, input {\n    display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
