import React, { useRef } from 'react';
import '../styles/Rick.css';

const Rick: React.FC = () => {
    const videoRef = useRef<HTMLVideoElement>(null);

    return (
        <main id='main' className='rick-main'>
            <h1 className='lang-fr'>Bienvenue sur le site du CIA !</h1>
            <h1 className='lang-en'>Welcome on CIA's website !</h1>
            <video ref={videoRef} src='/videos/rick_roll.webm' controls autoPlay loop style={{width: '60%'}} />
        </main>  
    );
};

export default Rick;
