// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hud {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    z-index: 999;
    color: white;
}

.hud-button {
    background-color: #333;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 24px;
    font-weight: bold;
}

.hud-button:hover {
    background-color: #555;
}
`, "",{"version":3,"sources":["webpack://./src/styles/HUD.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,YAAY;IACZ,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,SAAS;IACT,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;IACf,iCAAiC;IACjC,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,sBAAsB;AAC1B","sourcesContent":[".hud {\n    position: fixed;\n    bottom: 20px;\n    right: 20px;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 10px;\n    z-index: 999;\n    color: white;\n}\n\n.hud-button {\n    background-color: #333;\n    border-radius: 50%;\n    width: 50px;\n    height: 50px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    cursor: pointer;\n    transition: background-color 0.3s;\n    font-size: 24px;\n    font-weight: bold;\n}\n\n.hud-button:hover {\n    background-color: #555;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
