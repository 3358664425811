import React, { useState } from 'react';
import { TeamScore } from '../script/Score';
import '../styles/Leaderboard.css';

export type TeamScoreProps = { scores: TeamScore[] };

const Leaderboard: React.FC<TeamScoreProps> = ({ scores }) => {
    const [openInfoBoxId, setOpenInfoBoxId] = useState<boolean>(false);

    if (scores.length === 0) {
        return (
            <main id='main' className='leaderboard-main'>
                <h3 className='lang-fr'>Podium</h3>
                <h3 className='lang-en'>Leaderboard</h3>
                <hr />
                <div className='leaderboard-score'>
                    <p style={{ fontSize: '32px' }}>No score for now</p>
                </div>
            </main>
        );
    }

    const toggleInfoBox = () => {
        setOpenInfoBoxId(!openInfoBoxId);
    };

    let max_score: number = 0;
    scores.forEach(score => {
        const tmp_score = (score.score + score.bonus) % 20000;
        if (tmp_score > max_score)
            max_score = tmp_score;
    });

    return (
        <main id='main' className='leaderboard-main'>
            <h3 className='lang-fr'>Podium</h3>
            <h3 className='lang-en'>Leaderboard</h3>
            <hr />
            <div className='leaderboard-score'>
                {scores.map(score => {
                    return (
                        <div className='team-score' key={score.team}>
                            <h4 style={{ color: `${score.team}` }}>{score.team.toUpperCase()}</h4>
                            <div className='team-bar-container'>
                                <div
                                    className='team-bar'
                                    style={{ background: `${score.team}`, maxHeight: `calc(100% * ${((score.score + score.bonus) % 20000)/max_score})` }}
                                >
                                </div>
                            </div>
                            {openInfoBoxId && (
                                <div className='team-detail' style={{ color: `${score.team}` }}>
                                    <div>
                                        <p className='lang-fr'>Membres</p>
                                        <p className='lang-en'>Members</p>
                                        <p>: {score.members}</p>
                                    </div>
                                    <div>
                                        <p>Score: {score.score + score.bonus}</p>
                                        <p> ({score.bonus > 0 ? '+' : ''}{score.bonus})</p>
                                    </div>
                                    <div>
                                        <p className='lang-fr'>Résolus</p>
                                        <p className='lang-en'>Solved</p>
                                        <p>: {score.solved}</p>
                                    </div>
                                    <div>
                                        <p className='lang-fr'>Temps en jeu</p>
                                        <p className='lang-en'>In-game time</p>
                                        <p>: {score.total_time.toString()}</p>
                                    </div>
                                    {score.times.map((time, index) => {
                                        return (
                                            <div>
                                                <p className='lang-fr'>Temps</p>
                                                <p className='lang-en'>Time</p>
                                                <p> {index+1}: {time.toString()}</p>
                                            </div>
                                        );
                                    })}
                                    <div>
                                        <p className='lang-fr'>Erreurs</p>
                                        <p className='lang-en'>Errors</p>
                                        <p>: {score.errors}</p>
                                    </div>
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
            <button onClick={toggleInfoBox}>
                <p className='lang-fr'>Détails</p>
                <p className='lang-en'>Details</p>
                <i className='fas fa-plus' style={{ display: `${openInfoBoxId ? 'none' : 'inline'}`}}/>
                <i className='fas fa-minus' style={{ display: `${openInfoBoxId ? 'inline' : 'none'}`}}/>
            </button>
        </main>
    );
};

export default Leaderboard;
