import React, { useState } from 'react';
import { percentageToColor, Time, GroupScore } from '../script/Score';
import '../styles/Scoreboard.css';

export type GroupScoreProps = { scores: GroupScore[] };

const Scoreboard: React.FC<GroupScoreProps> = ({ scores }) => {
    const [sortedField, setSortedField] = useState<keyof GroupScore | null>(null);
    const [sortedTimesIndex, setSortedTimesIndex] = useState<number | null>(null);
    const [ascending, setAscending] = useState(true);

    const handleSort = (field: keyof GroupScore | null, index: number | null = null) => {
        if (field !== null) {
            if (sortedField === field)
                setAscending(!ascending);
            else {
                setSortedField(field);
                setSortedTimesIndex(null);
                setAscending(true);
            }
        } else if ((index !== null || index === 0)) {
            if (sortedTimesIndex === index)
                setAscending(!ascending);
            else {
                setSortedTimesIndex(index);
                setSortedField(null);
                setAscending(true);
            }
        }
    };

    const sortScores = (field: keyof GroupScore | null, index: number | null = null): GroupScore[] => {
        const sortedScores = [...scores].sort((a, b) => {
            if (field) {
                const fieldA = a[field];
                const fieldB = b[field];
                if (fieldA instanceof Time && fieldB instanceof Time)
                    return (fieldA as Time).get() - (fieldB as Time).get();
                else
                    return fieldA > fieldB ? 1 : fieldA < fieldB ? -1 : 0;
            } else if ((index !== null || index === 0) && index < a.times.length && index < b.times.length) {
                const timeA = a.times[index]?.get() || 0;
                const timeB = b.times[index]?.get() || 0;
                if (timeA >= a.total_time.get()) {
                    if (timeB >= b.total_time.get())
                        return 0;
                    return -1 * (ascending ? -1 : 1);
                }
                if (timeB >= b.total_time.get())
                    return 1 * (ascending ? -1 : 1);
                return timeA - timeB;
            }
            return 0;
        });
        if (!ascending)
            sortedScores.reverse();
        return sortedScores;
    };

    const sortedScores = sortedField ? sortScores(sortedField) : (sortedTimesIndex === null ? scores : sortScores(null, sortedTimesIndex));
    const nb_enigmes = scores.reduce((max, current) => Math.max(max, current.times.length), 0);

    return (
        <main id='main' className='scoreboard-main'>
            <h3 className='lang-fr'>Tableau des résultats</h3>
            <h3 className='lang-en'>Scoreboard</h3>
            <hr />
            <table className='scoreboard-table'>
                <thead>
                    <tr>
                        <th onClick={() => handleSort('room')}>
                            <p className='lang-fr'>Salle</p>
                            <p className='lang-en'>Room</p>
                        </th>
                        <th onClick={() => handleSort('slot')}>
                            <p className='lang-fr'>Créneau</p>
                            <p className='lang-en'>Slot</p>
                        </th>
                        <th onClick={() => handleSort('team')}>
                            <p className='lang-fr'>Équipe</p>
                            <p className='lang-en'>Team</p>
                        </th>
                        <th onClick={() => handleSort('group')}>
                            <p className='lang-fr'>Groupe</p>
                            <p className='lang-en'>Group</p>
                        </th>
                        <th onClick={() => handleSort('members')}>
                            <p className='lang-fr'>Nb. membres</p>
                            <p className='lang-en'>Nb. members</p>
                        </th>
                        <th onClick={() => handleSort('score')}>
                            <p>Score</p>
                        </th>
                        <th onClick={() => handleSort('total_time')}>
                            <p className='lang-fr'>Temps en jeu</p>
                            <p className='lang-en'>In-game time</p>
                        </th>
                        <th onClick={() => handleSort('solved')}>
                            <p className='lang-fr'>Nb. résolus</p>
                            <p className='lang-en'>Nb. solved</p>
                        </th>
                        {Array.from({ length: nb_enigmes }, (_, index) => (
                            <th onClick={() => handleSort(null, index)}>
                                <p className='lang-fr'>Enigme {index + 1}</p>
                                <p className='lang-en'>Enigma {index + 1}</p>
                            </th>
                        ))}
                        <th onClick={() => handleSort('errors')}>
                            <p className='lang-fr'>Nb. erreurs</p>
                            <p className='lang-en'>Nb. errors</p>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {sortedScores.map((score) => (
                        <tr>
                            <td>{score.room}</td>
                            <td>{score.slot}</td>
                            <td style={{color: score.team}}>{score.team.toUpperCase()}</td>
                            <td>{score.group}</td>
                            <td>{score.members}</td>
                            <td>{score.score}</td>
                            <td>{score.total_time.toString()}</td>
                            <td style={{background: percentageToColor(score.solved / nb_enigmes * 100)}}>{score.solved}</td>
                            {Array.from({ length: nb_enigmes }, (_, index) => {
                                if (score.times.length <= index)
                                    return (<td></td>);

                                const time = score.times[index];

                                if (score.total_time.get() <= time.get())
                                    return (<td></td>);

                                return (
                                    <td style={{background: percentageToColor(100 * (1 - time.get() / score.total_time.get()))}}>
                                        {time.toString()}
                                    </td>
                                );
                            })}
                            <td>{score.errors}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </main>
    );
};

export default Scoreboard;
